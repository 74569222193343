import React from "react"
import { connect } from "react-redux"

import { addItem } from "../../redux/cart/cart.actions"

import CustomButton from "../custom-button/custom-button.component"

import "./detalleDescripcion.styles.css"

// const DetalleMenu = ({item, addItem}) => {
//     const { name, fotoSegunda, fotoPrimera } = item;

const DetalleDescripcion = ({ name, title, description, price }) => {
  // const { name, fotoSegunda, fotoPrimera } = item;

  return (
    <div className="descripcionItem">
      <div className="tituloDescripcion">
        <h2 className="productName unselectable"> {name}</h2>
        <h4 className="productName unselectable"> {title}</h4>
      </div>
      <div className="textoDescripcion">
        <p className="productName unselectable"> {description}</p>
        <p className="productName unselectable"> {price}</p>

        {/* <CustomButton onClick={() => addItem(item)} inverted>
            + INFORMACI&Oacute;N
            </CustomButton> */}
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  addItem: item => dispatch(addItem(item))
})

export default connect(null, mapDispatchToProps)(DetalleDescripcion)
