import React from 'react'
import './contacto.styles.css'
import Formulario from '../contactoformulario/contactoformulario.component'
import Whats from '../contactowhats/contactowhats.component'
import logo from '../../dobleR.svg'
import { Element } from 'react-scroll'

const Contacto = ({ imageUrl }) => (
  <Element name="contacto">
    <div>
      <div className="contenedorContacto">
        <Whats />
        {/* <Formulario /> */}
      </div>
      <div className="contactoDobleErre">
        <img src={logo} alt="DobleErre" />
      </div>
    </div>
  </Element>
)

export default Contacto
