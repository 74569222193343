import React from "react"
import { connect } from "react-redux"

import { withRouter } from "react-router-dom"
import CustomButton from "../custom-button/custom-button.component"
import { addItem } from "../../redux/cart/cart.actions"

import "./collection-item.styles.css"

const CollectionItem = ({ item, match, history, addItem }) => {
  const { name, fotoSegunda, fotoPrimera, linkUrlC, estado } = item
  // console.log("collection item component")
  // console.log(item)
  return (
    <div
      className="productDisplay"
      onClick={() => history.push(`${match.url}${linkUrlC}`)}
    >
      <div className="imagesItems">
        <div className={`${estado} etiquetaVendidoCol`}>
          <p className="vendidoCol">VENDIDO</p>
        </div>

        <img
          className="unselectable fotoProducto"
          src={fotoPrimera}
          alt={name}
        />
        <img
          className="unselectable fotoProductoTop"
          src={fotoPrimera}
          alt={name}
        />
      </div>
      <div className="CollectionFooter">
        <h2 className="productName unselectable"> {name.toUpperCase()}</h2>
        {/* <Link className='masInformacion'> + INFORMACI&Oacute;N </Link> */}

        <CustomButton onClick={() => addItem(item)} inverted>
          + INFORMACI&Oacute;N
        </CustomButton>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  addItem: item => dispatch(addItem(item))
})

export default connect(null, mapDispatchToProps)(withRouter(CollectionItem))

// export default connect (null, mapDispatchToProps)(withRouter(CollectionItem));
