import React from 'react'
import { connect } from 'react-redux'

import { createStructuredSelector } from 'reselect'

import ItemCollection from '../../components/item-collection/item-collection.component'

// import CollectionPreview from '../../components/collection-preview/collection-preview.component';

import Contacto from '../../components/contacto/contacto.component'

import { selectCollection } from '../../redux/shop/shop.selectors'

// import { selectCollectionsForPreview } from '../../redux/shop/shop.selectors';

import './category.styles.css'

import FlechaAbajo from '../../flechaAbajo.svg'

const CategoryPage = ({ collection }) => {
  // console.log('saludos del futuro');
  // console.log(match);
  console.log('read this:')
  const { title, items } = collection
  console.log(collection)
  return (
    <div className="category">
      <div className="tituloCategory">
        <p>
          COLECCI&Oacute;N
          <br />
          <span className="title">
            {title.toUpperCase()}
            <img className="flechaAbajo" src={FlechaAbajo} alt="bajar" />
          </span>
        </p>
      </div>

      <div className="items">
        {items.map(item => (
          <ItemCollection key={item.id} item={item} />
        ))}
      </div>

      {/* <Contacto/> */}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  collection: selectCollection(ownProps.match.params.collectionId)(state)
})

// const mapStateToProps = createStructuredSelector({
//   collection: selectCollectionsForPreview
// });

export default connect(mapStateToProps)(CategoryPage)
