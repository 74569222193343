import React from "react"
import { Link } from "react-router-dom"
import { withRouter } from "react-router-dom"
import "./nuevasfotos.styles.css"

const NuevasFotos = ({ title, imageUrl, link }) => (
  <div className="eachFotoNueva">
    <Link className="contenedorFotoNueva" to={link}>
      <h2>{title}</h2>
      <div className="fotoNuevaBtn">+INFO</div>
      <div
        className="fotoNueva"
        style={{
          backgroundImage: `url(${imageUrl})`
        }}
      ></div>
    </Link>
  </div>
)

export default withRouter(NuevasFotos)
