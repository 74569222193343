import React from "react"
import "./contactoformulario.styles.css"
import axios from "axios"

class Formulario extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      nombre: "",
      email: "",
      pais: "",
      cp: "",
      mensaje: "",
      mailSent: false,
      error: null
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    axios({
      method: "post",
      url: "http://morrisart.com.mx/mail.php",
      data: this.state
    })
      .then(response => {
        this.setState(
          {
            mailSent: response.data.sent
          },
          () => console.log(this.state)
        ) // NB! setState accepts callbacks

        if (response.data.status === "success") {
          alert(this.state.nombre + " tu mensaje fue enviado.")
          this.resetForm()
        } else if (response.data.status === "fail") {
          alert(this.state.nombre + " el mensaje no se envio")
        }
      })
      .catch(error => this.setState({ error: error.message }))

    // .then(result => {
    //     this.setState({
    //       mailSent: result.data.sent
    //     }, () => console.log(this.state)) // NB! setState accepts callbacks
    //   })
    //   .catch(error => this.setState({ error: error.message }));
  }

  resetForm() {
    this.setState({ nombre: "", email: "", pais: "", cp: "", mensaje: "" })
  }

  render() {
    return (
      <div className="formulario unselectable">
        <h1>CONT&Aacute;CTANOS</h1>

        <form onSubmit={this.handleSubmit.bind(this)} method="POST" action="">
          <label htmlFor="nombre">NOMBRE COMPLETO*</label>

          <input
            onChange={this.onNombreChange.bind(this)}
            type="text"
            id="nombre"
            name="nombre"
            placeholder=""
            value={this.state.nombre}
          />

          <label htmlFor="email">EMAIL*</label>

          <input
            onChange={this.onEmailChange.bind(this)}
            type="email"
            id="email"
            name="email"
            placeholder=""
            value={this.state.email}
          />

          <label htmlFor="pais">PA&Iacute;S</label>

          <input
            onChange={this.onPaisChange.bind(this)}
            type="text"
            id="pais"
            name="pais"
            placeholder=""
            value={this.state.pais}
          />

          <label htmlFor="cp">CP</label>

          <input
            onChange={this.onCpChange.bind(this)}
            type="text"
            id="cp"
            name="cp"
            placeholder=""
            value={this.state.cp}
          />

          <label htmlFor="mensaje">MENSAJE*</label>

          <textarea
            onChange={this.onMensajeChange.bind(this)}
            id="mensaje"
            name="mensaje"
            placeholder=""
            value={this.state.mensaje}
          ></textarea>

          <input type="submit" value="ENVIAR" />

          <div>
            {this.state.mailSent && <div>Thank you for contacting us.</div>}
          </div>
        </form>
      </div>
    )
  }

  onNombreChange(event) {
    this.setState({ nombre: event.target.value })
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value })
  }

  onPaisChange(event) {
    this.setState({ pais: event.target.value })
  }

  onCpChange(event) {
    this.setState({ cp: event.target.value })
  }

  onMensajeChange(event) {
    this.setState({ mensaje: event.target.value })
  }
}

export default Formulario

// const Formulario = ({ title, imageUrl }) =>(
//     <div className='formulario'>
//     <h1>CONT&Aacute;CTANOS</h1>
//                 <form action="">
//                     <label for="nombre">NOMBRE COMPLETO*</label>
//                     <input type="text" id="nombre" name="nombre" placeholder="John"></input>
//                     <label for="email">EMAIL*</label>
//                     <input type="email" id="email" name="email" placeholder="mail@mail.com"></input>
//                     <label for="pais">PAIS</label>
//                     <input type="text" id="pais" name="pais" placeholder="M&eacute;xico"></input>
//                     <label for="cp">CP</label>
//                     <input type="text" id="cp" name="cp" placeholder="00000"></input>
//                     <label for="mensaje">MENSAJE*</label>
//                     <textarea id="mensaje" name="mensaje" placeholder="Mensaje"></textarea>
//                     <input type="submit" value="ENVIAR"></input>
//                 </form>
//     </div>
// );

// export default Formulario
