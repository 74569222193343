import React from "react"
import "./menu.styles.css"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
// import { scroller } from 'react-scroll';
// import {Link as ScrollLink} from 'react-scroll';

const Menuitem = ({ nombre, link }) => (
  <Link className="menuBtn" to={link}>
    <div className="menuBtn">
      <p> {nombre} </p>
    </div>
  </Link>
)

export default withRouter(Menuitem)
