import React from "react"
import { connect } from "react-redux"

import { addItem } from "../../redux/cart/cart.actions"

import CustomButton from "../custom-button/custom-button.component"

import "./detalleMenu.styles.css"

// const DetalleMenu = ({item, addItem}) => {
//     const { name, fotoSegunda, fotoPrimera } = item;

const DetalleMenu = ({ name, fotoSegunda, fotoPrimera }) => {
  // const { name, fotoSegunda, fotoPrimera } = item;

  return (
    <div className="detalleMenu">
      <div className="imagesMenu">
        <img
          className="unselectable iconProducto"
          src={fotoPrimera}
          alt={name}
        />

        <img
          className="unselectable iconProducto"
          src={fotoSegunda}
          alt={name}
        />

        <img
          className="unselectable iconProducto"
          src={fotoPrimera}
          alt={name}
        />

        <img
          className="unselectable iconProducto"
          src={fotoSegunda}
          alt={name}
        />
      </div>
      <div className="CollectionFooter">
        <h2 className="productName unselectable"> {name}</h2>

        {/* <CustomButton onClick={() => addItem(item)} inverted>
            + INFORMACI&Oacute;N
            </CustomButton> */}
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  addItem: item => dispatch(addItem(item))
})

export default connect(null, mapDispatchToProps)(DetalleMenu)
