import React, { useRef } from 'react'

import { connect } from 'react-redux'

import {
  CarouselProvider,
  Slider,
  Slide,
  ImageWithZoom,
  ButtonBack,
  ButtonNext,
  DotGroup,
  Dot
} from 'pure-react-carousel'

import 'pure-react-carousel/dist/react-carousel.es.css'

import './itempage.styles.css'

import loWhats from '../../whatsApp.svg'

import { createStructuredSelector } from 'reselect'

import Nuevas from '../../components/nuevas/nuevas.component'

import Contacto from '../../components/contacto/contacto.component'

import FlechaAbajo from '../../flechaAbajo.svg'

import DetalleItem from '../../components/detalle-item/detalle-item.component'
import { selectCollectionItem } from '../../redux/shop-item/shop-item.selectors'

import { selectItemsForPreview } from '../../redux/shop-item/shop-item.selectors'

import NumberFormat from 'react-number-format'

const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop)
// General scroll to element function

const ItemPage = ({ detailItem }) => {
  const myRef = useRef(null)
  const executeScroll = () => scrollToRef(myRef)

  console.log('acaAndo')

  const {
    name,
    title,
    fotoPrimera,
    fotoSegunda,
    fotoTercera,
    fotoCuarta,
    fecha,
    largo,
    peso,
    price,
    dolares,
    estado,
    caja,
    addClass
  } = detailItem

  var mensaje = ''

  if (estado === 'vendido') {
    mensaje = `La%20cuchara%20${name}%20está%20vendida%20¿hay%20alguna%20otra%20que%20te%20guste?`
  } else {
    mensaje = `Comprar%20la%20cuchara%20${name}%20es%20muy%20fácil,%20escríbeme`
  }

  console.log(mensaje)

  return (
    <div className="detallePage">
      <div className="details">
        <CarouselProvider
          visibleSlides={1}
          naturalSlideWidth={300}
          naturalSlideHeight={605}
          totalSlides={4}
          hasMasterSpinner
          lockOnWindowScroll
          step={1}
        >
          <div className="divDots">
            <Dot className="dotClass" slide={0}>
              <img
                className="unselectable fotoIcon"
                src={fotoPrimera}
                alt={name}
              />
            </Dot>
            <Dot className="dotClass" slide={1}>
              <img
                className="unselectable fotoIcon"
                src={fotoSegunda}
                alt={name}
              />
            </Dot>
            <Dot className="dotClass" slide={2}>
              <img
                className="unselectable fotoIcon"
                src={fotoTercera}
                alt={name}
              />
            </Dot>
            <Dot className="dotClass" slide={3}>
              <img
                className="unselectable fotoIcon"
                src={fotoCuarta}
                alt={name}
              />
            </Dot>
          </div>

          <div className={`${estado} etiquetaVendido`}>
            <p className="vendido">VENDIDO</p>
          </div>

          <Slider className="sliderPosition">
            <Slide index={0}>
              <ImageWithZoom src={fotoPrimera} />
            </Slide>
            <Slide index={1}>
              <ImageWithZoom src={fotoSegunda} />
            </Slide>
            <Slide index={2}>
              <ImageWithZoom src={fotoTercera} />
            </Slide>
            <Slide index={3}>
              <ImageWithZoom src={fotoCuarta} />
            </Slide>
          </Slider>

          {/* <DotGroup dotNumbers disableActiveDots showAsSelectedForCurrentSlideOnly /> */}
        </CarouselProvider>

        {/* </div> */}

        <div className="textDetails">
          <div className="tituloItem">
            <p>
              {title.toUpperCase()}
              <br />
            </p>
            <div
              data-aos="flip-up"
              //  data-aos="zoom-in-up"
              data-aos-offset="100"
              data-aos-delay="4"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="false"
              //  data-aos-anchor-placement="center-center"

              className="titleItem"
            >
              {name.toUpperCase()}
              <img
                data-aos="flip-up"
                //  data-aos="zoom-in-up"
                data-aos-offset="100"
                data-aos-delay="10"
                data-aos-duration="2000"
                data-aos-easing="ease-in-out"
                data-aos-mirror="true"
                data-aos-once="false"
                data-aos-anchor-placement="top-bottom"
                className="flechaAbajo"
                src={FlechaAbajo}
                alt="bajar"
              />
            </div>
          </div>
          <p>FECHA: {fecha}</p>
          <p>LARGO: {largo}</p>
          <p>PESO: {peso}</p>
          {/* <p>PRECIO: ${price}</p> */}
          {!addClass && (
            <>
              <p className={addClass}>
                PRECIO:&nbsp;
                <NumberFormat
                  value={price}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                />
              </p>

              <p className={addClass}>
                PRECIO EN DOLARES:&nbsp;
                <NumberFormat
                  value={dolares}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                />
              </p>

              <div className="envio">
                <br />
                <br />
                Precio m&aacute;s gastos de envio.
              </div>
            </>
          )}

          {addClass && (
            <div className="exhibicion">
              Encu&eacute;ntrala en Exhibici&oacute;n, visita la tienda{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="exhibicionBtn"
                href="https://www.facebook.com/akashaconceptstore/"
              >
                Akasha Concept Store
              </a>{' '}
              en Centro Comercial Coyoac&aacute;n.
            </div>
          )}
        </div>
      </div>

      <a
        className="whatsBtnDetail"
        target="_blank"
        rel="noopener noreferrer"
        href={`https://api.whatsapp.com/send?phone=5215554346399&text=${mensaje}`}
      >
        Para m&aacute;s informaci&oacute;n <br />
        comun&iacute;cate con el artista:
        <br />
        <br />
        <img className="loWhatsDetail" src={loWhats} alt="whatsapp" />
      </a>

      {/* https://api.whatsapp.com/send?phone=5215554346399&text=&#191;Qu&eacute;%20informaci&oacute;n%20requieres&#63;%20escr&iacute;beme%20Ruperto */}

      {/* https://api.whatsapp.com/send?phone=5215554346399&text=¿Qué%20información%20requieres?%20escríbeme%20 */}

      <div className="recibir">
        <div className="recibirTitulo">
          <p>Lo que vas a recibir:</p>
        </div>
        <div className="recibirImages">
          <div className="cuadranteRecibir">
            <div
              className="piezaRecibir"
              style={{
                backgroundImage: `url(${fotoPrimera})`,
                backgroundSize: 'cover',
                backgroundPositionY: 'bottom'
              }}
            ></div>
            {/* <img src={fotoTercera} alt={name} srcset=""/> */}
            <p>
              La pieza <strong>"{name}"</strong>
            </p>
          </div>

          <div className="cuadranteRecibir">
            <div className="piezaRecibir certificado"></div>
            <p>Certificado de Autenticidad</p>
          </div>
          <div className="cuadranteRecibir">
            <div className={`${caja} piezaRecibir`}></div>
            <p>{/* Caja a la medida */}</p>
          </div>

          <div className="cuadranteRecibir">
            <div className={`${caja} piezaRecibir`}></div>

            <p style={{ textAlign: 'right' }}>
              *Pregunta por el color <br />
              de las cajas disponibles.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  detailItem: selectCollectionItem(ownProps.match.params.detailId)(state)
})

export default connect(mapStateToProps)(ItemPage)
