import React from "react"
import "./palabrasinter.styles.css"
import DobleErre from "../../dobleR.svg"

const PalabrasInter = () => (
  <div className="palabrasInter">
    <div className="dobleErreInter">
      <img src={DobleErre} alt="DobleErreInter" />
    </div>
    <div
      data-aos="zoom-out-down"
      //  data-aos="zoom-in-up"
      data-aos-offset="200"
      data-aos-delay="4"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false"
      data-aos-anchor-placement="top-bottom"
      className="contenedortxtInter"
    >
      <h2 className="txtInter">
        <q>
          {" "}
          El origen de los cubiertos es el resultado de buscar en diferentes
          mercados o restaurantes, casi todos son de acero inoxidable de
          diferente dureza y calibres, en ocasiones pueden ser de alguna
          aleaci&oacute;n de n&iacute;quel y plata.
        </q>
      </h2>
    </div>
  </div>
)

export default PalabrasInter
