import React from "react"
// import './uso.styles.scss'

import Fotosdeuso from "../../components/fotosdeuso/fotosdeuso.component"

import AOS from "aos"
import "aos/dist/aos.css" // You can also use <link> for styles

class Uso extends React.Component {
  constructor() {
    super()

    this.state = {
      sections: [
        {
          id: 4,
          coleccion: "luka",
          foto: "http://morrisart.com.mx/images/media/products/uso-001-DSC_1611_6A4wzGE.png",
          informacion: "",
          link: ""
        },
        {
          id: 1,
          coleccion: "sili",
          foto: "http://morrisart.com.mx/images/media/products/uso-002-DSC_1611.png",
          informacion: "sili es caracol en grecoromani azteka",
          link: ""
        },
        {
          id: 5,
          coleccion: "sili mexika",
          foto: "http://morrisart.com.mx/images/media/products/uso-005-DSC_1611.png",
          informacion: "",
          link: ""
        },
        {
          id: 6,
          coleccion: "sili",
          foto: "http://morrisart.com.mx/images/media/products/uso-006-DSC_1611.png",
          informacion: "sili es caracol en grecoromani azteka",
          link: ""
        },
        {
          id: 2,
          coleccion: "sili mexika",
          foto: "http://morrisart.com.mx/images/media/products/uso-003-DSC_1611.png",
          informacion: "",
          link: ""
        },
        {
          id: 3,
          coleccion: "luka mexica",
          foto: "http://morrisart.com.mx/images/media/products/uso-004-DSC_1611.png",
          informacion: "",
          link: ""
        }
      ]
    }
  }

  render() {
    return (
      <div className="classUso">
        {this.state.sections.map(({ foto, id }) => (
          <Fotosdeuso key={id} imageUrl={foto} />
        ))}
      </div>
    )
  }
}

// data-aos="fade-up"
// data-aos-offset="200"
// data-aos-delay="50"
// data-aos-duration="1000"
// data-aos-easing="ease-in-out"
// data-aos-mirror="true"
// data-aos-once="false"
// data-aos-anchor-placement="top-center"

// ..
AOS.init()

export default Uso
