import React from 'react'
import { Route } from 'react-router-dom'

import CollectionsOverview from '../../components/collections-overview/collections-overview.component'

import CategoryPage from '../category/category.component'

import ItemPage from '../itempage/itempage.component'

import NotFoundPage from '../notfound/notfound.component'

import '../homepage/homepage.styles.css'
import './collectionpage.styles.css'

import Contacto from '../../components/contacto/contacto.component'

const CollectionPage = ({ match }) => (
  <div className="collection-page">
    <div className="indicator"></div>
    <div className="noiseDiv"></div>

    <Route exact path={`${match.path}`} component={CollectionsOverview} />

    <Route
      exact
      path={`${match.path}/:collectionId/:detailId`}
      component={ItemPage}
    />

    <Route path={`${match.path}/:collectionId`} component={CategoryPage} />

    {/* <Route component={NotFoundPage}/> */}

    {/* <div className='tituloNuevas'>NUEVAS CREACIONES</div> */}
    {/* <Nuevas /> */}
    <Contacto />
  </div>
)

export default CollectionPage
