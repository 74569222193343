import React from "react"
import "./avisop.styles.css"

// import Nuevas from '../../components/nuevas/nuevas.component';

import Contacto from "../../components/contacto/contacto.component"

const Aviso = () => (
  <div>
    <div className="indicator"></div>
    <div className="noiseDiv"></div>

    <div className="avisoPage">
      <div className="titulo">AVISO DE PRIVACIDAD</div>

      <div className="bio">
        <div className="avisoColumn">
          <p className="textAviso">
            Morris es consciente de la responsabilidad y confianza que usted
            deposita en nosotros, es por eso que se encuentra comprometida con
            el buen tratamiento que dar&aacute; a sus Datos Personales, por lo
            que en todo momento guardar&aacute; la confidencialidad de la
            informaci&oacute;n que se le proporciona y actuar&aacute; conforme a
            la regulaci&oacute;n nacional e internacional aplicable en materia
            de Datos Personales.
            <br />
            <br />
            Asimismo, Morris agradece su inter&eacute;s en la p&aacute;gina web
            www.morrisart.com.mx. y en el presente Aviso de Privacidad,
            raz&oacute;n por la que se pone a sus &oacute;rdenes para cualquier
            duda o comentario que tenga sobre el presente Aviso de Privacidad en
            el contacto que se identifica como responsable en este documento.
            <br />
            <br />
            Ahora bien, en cumplimiento a la Ley Federal de Protecci&oacute;n de
            Datos Personales en Posesi&oacute;n de Particulares, su Reglamento,
            as&iacute; como los Lineamientos del Aviso de Privacidad publicados
            en el Diario Oficial de la Federaci&oacute;n el 17 de enero de 2013,
            se hace de su conocimiento el presente Aviso de Privacidad.
            <br />
            <br />
            I. RESPONSABLE.
            <br />
            El Representante del Tratamiento de Datos Personales es Rodolfo
            Kelly Ram&iacute;rez como persona f&iacute;sica, quien es el autor
            de los derechos de propiedad intelectual de los productos ofrecidos
            como parte de proyecto art&iacute;stico y comercial de Morris, cuyo
            correo de contacto es rkellyr@me.com, con domicilio en Eje 4 Sur
            Chilpancingo 148 - 301, colonia Roma Sur, Alcald&iacute;a
            Cuauht&eacute;moc, C.P. 06760, Ciudad de M&eacute;xico.
            <br />
            <br />
            II. FINALIDADES.
            <br />
            Las finalidades por las que Morris podr&iacute;a solicitar, obtener,
            almacenar y/o utilizar los Datos Personales son las siguientes:
            <ul>
              <li>
                - Dar seguimiento a la compraventa de los productos ofrecidos y
                generar un canal de comunicaci&oacute;n clara y transparente con
                la clientela;
              </li>
              <li>
                - Integrar un registro de clientes, prospectos, proveedores o
                cualquier interesado en tener comunicaci&oacute;n con Morris;
              </li>
              <li>
                - Atender a la solicitud de informaci&oacute;n, productos,
                servicios, quejas, preguntas o comentarios, as&iacute; como la
                actualizaci&oacute;n de los nuevos productos y servicios que
                ofrece Morris;
              </li>
              <li>
                - Creaci&oacute;n de bases de datos para fines de contacto con
                clientes, proveedores y para fines de control de
                facturaci&oacute;n; y/o
              </li>
              <li>
                - Para fines mercadol&oacute;gicos, publicitarios y de
                prospecci&oacute;n comercial.
              </li>
            </ul>
            <br />
            III. CONSENTIMIENTO PARA EL USO DE DATOS PERSONALES.
            <br />
            El tratamiento de los Datos Personales estar&aacute; siempre sujeto
            a que el Titular proporcione su consentimiento ya sea de manera
            expresa, manifestando su voluntad de manera verbal, por medios
            electr&oacute;nicos, &oacute;pticos, por cualquier otra
            tecnolog&iacute;a o por signos inequ&iacute;vocos, o bien de manera
            t&aacute;cita, entendiendo por conocimiento t&aacute;cito cuando
            habi&eacute;ndose puesto a disposici&oacute;n del Titular el Aviso
            de Privacidad, &eacute;ste no manifieste oposici&oacute;n alguna.
            <br />
            <br />
            Para ambos consentimientos el Titular de los Datos Personales cuenta
            con un plazo de 5 d&iacute;as para manifestar su oposici&oacute;n al
            tratamiento de dichos Datos Personales, en caso de que no se
            manifieste oposici&oacute;n se entender&aacute; que se ha
            manifestado su consentimiento.
            <br />
            <br />
            IV. DATOS PERSONALES SOLICITADOS.
            <br />
            Para las finalidades antes señaladas se solicitar&aacute;n los
            siguientes datos personales: Nombre y Apellidos, Pa&iacute;s,
            C&oacute;digo Postal y Correo Electr&oacute;nico. Se informa que no
            se recaban datos personales sensibles ni biom&eacute;tricos. Se
            utilizar&aacute;n medidas de seguridad de la informaci&oacute;n para
            proteger la informaci&oacute;n obtenida y almacenada.
            <br />
            <br />
            V. TRANSFERENCIA DE DATOS.
            <br />
            Se informa que no se realizar&aacute;n transferencias que requieran
            su consentimiento, salvo aquellas que sean necesarias para atender
            requerimientos de informaci&oacute;n de una autoridad competente,
            debidamente fundados y motivados.
            <br />
            <br />
            VI. DERECHOS ARCO
            <br />
            Usted tiene derecho a conocer qu&eacute; datos personales se tienen
            de usted, para qu&eacute; se utilizan y las condiciones del uso que
            les damos (Acceso). Asimismo, es su derecho solicitar la
            correcci&oacute;n de su informaci&oacute;n personal en caso de que
            est&eacute; desactualizada, sea inexacta o incompleta
            (Rectificaci&oacute;n); que la eliminemos de nuestros registros o
            bases de datos cuando considere que la misma no est&aacute; siendo
            utilizada conforme a los principios, deberes y obligaciones
            previstas en la ley (Cancelaci&oacute;n); as&iacute; como oponerse
            al uso de sus datos personales para fines espec&iacute;ficos
            (Oposici&oacute;n). Estos derechos se conocen como derechos ARCO.
            <br />
            <br />
            Para poder hacer valer los derechos ARCO, es necesario que el
            Titular de los Datos Personales realice una solicitud con los
            requisitos que se detallan a continuaci&oacute;n: (i) el nombre y
            domicilio o correo electr&oacute;nico del Titular para comunicarle
            la respuesta a su solicitud; (ii) los documentos que acrediten la
            identidad del Titular, o en su caso, la representaci&oacute;n legal
            de quien act&uacute;e en su nombre; (iii) la descripci&oacute;n
            clara y precisa de los Datos Personales respecto de los que el
            Titular busca ejercer alguno de los derechos antes mencionados; (iv)
            cualquier otro elemento o documento que facilite la
            localizaci&oacute;n de los Datos Personales del Titular; (v)
            especificar claramente si la solicitud es de acceso,
            rectificaci&oacute;n, cancelaci&oacute;n u oposici&oacute;n; (vi) el
            motivo de la solicitud; y (vii) las modificaciones a realizarse en
            caso de que la solicitud sea para la rectificaci&oacute;n de Datos
            Personales.
            <br />
            <br />
            Esta solicitud deber&aacute; de hacerla llegar a Morris por
            cualquiera de los siguientes medios:
            <ul>
              <li>- V&iacute;a correo electr&oacute;nico.</li>
              <li>- Correo postal.</li>
              <li>
                - Personalmente en la direcci&oacute;n postal indicada en este
                documento.
              </li>
            </ul>
            El procedimiento que Morris adoptar&aacute; una vez que haya
            recibido la solicitud consta en lo siguiente: Har&aacute; de su
            conocimiento en un plazo m&aacute;ximo de 20 (veinte) d&iacute;as
            naturales, contados a partir de la fecha en que haya recibido la
            solicitud, la determinaci&oacute;n adoptada, y en caso de que la
            misma resulte procedente, se har&aacute; efectiva dentro de los 15
            (quince) d&iacute;as naturales siguientes a la fecha en que se
            comunique su procedencia. Se le informa que los plazos antes
            referidos, Morris podr&aacute; ampliarlos cuando las
            particularidades del caso as&iacute; lo ameriten. Lo anterior se le
            notificar&aacute; al Titular por el mismo medio en que fue realizada
            la solicitud.
            <br />
            <br />
            Morris podr&aacute; negar el acceso, rectificaci&oacute;n,
            cancelaci&oacute;n u oposici&oacute;n al tratamiento de los mismos,
            en los siguientes supuestos:
            <br />
            I. Cuando el solicitante no sea el titular de los datos personales,
            o el representante legal no est&eacute; debidamente acreditado para
            ello;
            <br />
            II. Cuando en su base de datos no se encuentren los datos personales
            del solicitante; III. Cuando se lesionen los derechos de un tercero;
            <br />
            IV. Cuando exista un impedimento legal, o la resoluci&oacute;n de
            una autoridad competente que restrinja el acceso a los datos
            personales o que no permita la rectificaci&oacute;n,
            cancelaci&oacute;n u oposici&oacute;n de los mismos;
            <br />
            V. Cuando la rectificaci&oacute;n, cancelaci&oacute;n u
            oposici&oacute;n haya sido previamente realizada.
          </p>
        </div>
      </div>
    </div>
    <Contacto />
  </div>
)

export default Aviso
