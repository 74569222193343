import React from "react"

import "./cart-item.styles.css"

const CartItem = ({ item: { fotoPrimera, price, name, quantity } }) => (
  <div className="cart-item">
    <img src={fotoPrimera} alt="item" />
    <div className="item-details">
      <span className="name">{name}</span>
      <span className="price">
        {quantity} x ${price}
      </span>
    </div>
  </div>
)

export default CartItem
