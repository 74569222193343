import React from "react"
import "./footer.styles.css"

import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

const Footer = link => (
  <div className="thefooter">
    <div className="contenedorFooter">
      <div className="footer">
        <h4 className="txtFooter">
          Todos los derechos reservados MORRIS® 2022
        </h4>
        <Link className="aviso" to="/aviso">
          AVISO DE PRIVACIDAD
        </Link>
      </div>
    </div>
  </div>
)

export default withRouter(Footer)
