import React from "react"
import NuevasFotos from "../nuevasfotos/nuevasfotos.component"
import "./nuevas.styles.css"

class Nuevas extends React.Component {
  constructor() {
    super()

    this.state = {
      sections: [
        {
          id: 1,
          coleccion: "El Prieto",
          foto: "http://morrisart.com.mx/images/elPrieto1.jpg",
          informacion: "el prieto",
          link: "colecciones/luka/6"
        },
        {
          id: 2,
          coleccion: "Walter",
          foto: "http://morrisart.com.mx/images/Walter1.jpg",
          informacion: "Walter",
          link: "colecciones/luka/20"
        },
        {
          id: 3,
          coleccion: "Huang",
          foto: "http://morrisart.com.mx/images/Huang1.jpg",
          informacion: "Huang",
          link: "colecciones/luka/21"
        },
        {
          id: 4,
          coleccion: "Primitivo",
          foto: "http://morrisart.com.mx/images/Primitivo1.jpg",
          informacion: "Primitivo",
          link: "colecciones/sili/22"
        }
      ]
    }
  }

  render() {
    return (
      <div className="contenedorNuevas unselectable">
        {this.state.sections.map(({ informacion, foto, id, link }) => (
          <NuevasFotos
            key={id}
            title={informacion}
            imageUrl={foto}
            link={link}
          />
        ))}
      </div>
    )
  }
}

export default Nuevas
