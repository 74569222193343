import React from "react"
import "./menu.styles.css"
import Menuitem from "./menuItem.component"

class Menu extends React.Component {
  constructor() {
    super()

    this.state = {
      sections: [
        {
          id: 14,
          name: "home",
          foto: "",
          informacion: "",
          link: "/"
        },
        {
          id: 11,
          name: "acerca de mi",
          foto: "",
          informacion: "sili es caracol en grecoromani azteka",
          link: "/acerca-de-mi"
        },
        {
          id: 12,
          name: "colecciones",
          foto: "",
          informacion: "",
          link: "/colecciones"
        },
        {
          id: 13,
          name: "contacto",
          foto: "",
          informacion: "",
          link: "/contacto"
        }
      ]
    }
  }

  render() {
    return (
      <div className="contenedorMenu">
        {this.state.sections.map(({ name, id, link }) => (
          <Menuitem key={id} nombre={name.toUpperCase()} link={link} />
        ))}
      </div>
    )
  }
}

export default Menu
