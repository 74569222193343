// reflex
const SHOP_DATA = {
  dosMil22: {
    id: 5,
    title: '2022',
    routeName: 'dosMil22',
    items: [
      {
        id: 24,
        name: 'Albino',
        fotoPrimera: 'http://morrisart.com.mx/images/albino1.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/albino2.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/albino3.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/albino4.jpg',
        price: 25,
        linkUrl: '/23',
        linkUrlC: '/dosMil22/23',
        estado: ''
      },
      {
        id: 25,
        name: 'Lino',
        fotoPrimera: 'http://morrisart.com.mx/images/lino1.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/lino2.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/lino3.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/lino4.jpg',
        price: 25,
        linkUrl: '/24',
        linkUrlC: '/dosMil22/24',
        estado: 'vendido'
      },
      // {
      //   id: 26,
      //   name: 'Nacho',
      //   fotoPrimera: 'http://morrisart.com.mx/images/nacho1.jpg',
      //   fotoSegunda: 'http://morrisart.com.mx/images/nacho2.jpg',
      //   fotoTercera: 'http://morrisart.com.mx/images/nacho3.jpg',
      //   fotoCuarta: 'http://morrisart.com.mx/images/nacho4.jpg',
      //   price: 25,
      //   linkUrl: '/25',
      //   linkUrlC: '/dosMil22/25',
      //   estado: 'vendido'
      // },
      // {
      //   id: 27,
      //   name: 'Pedro',
      //   fotoPrimera: 'http://morrisart.com.mx/images/pedro1.jpg',
      //   fotoSegunda: 'http://morrisart.com.mx/images/pedro2.jpg',
      //   fotoTercera: 'http://morrisart.com.mx/images/pedro3.jpg',
      //   fotoCuarta: 'http://morrisart.com.mx/images/pedro4.jpg',
      //   price: 25,
      //   linkUrl: '/26',
      //   linkUrlC: '/dosMil22/26',
      //   estado: 'vendido'
      // },
      {
        id: 28,
        name: 'El Púas',
        fotoPrimera: 'http://morrisart.com.mx/images/elPuas1.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/elPuas2.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/elPuas3.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/elPuas4.jpg',
        price: 25,
        linkUrl: '/27',
        linkUrlC: '/dosMil22/27',
        estado: ''
      },
      // {
      //   id: 29,
      //   name: 'Roberto',
      //   fotoPrimera: 'http://morrisart.com.mx/images/roberto1.jpg',
      //   fotoSegunda: 'http://morrisart.com.mx/images/roberto2.jpg',
      //   fotoTercera: 'http://morrisart.com.mx/images/roberto3.jpg',
      //   fotoCuarta: 'http://morrisart.com.mx/images/roberto4.jpg',
      //   price: 25,
      //   linkUrl: '/28',
      //   linkUrlC: '/dosMil22/28',
      //   estado: 'vendido'
      // },
      {
        id: 30,
        name: 'Bartolo',
        fotoPrimera: 'http://morrisart.com.mx/images/bartolo1.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/bartolo2.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/bartolo3.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/bartolo4.jpg',
        price: 25,
        linkUrl: '/29',
        linkUrlC: '/dosMil22/29',
        estado: ''
      },
      {
        id: 31,
        name: 'Elefante 1',
        fotoPrimera: 'http://morrisart.com.mx/images/elefante11.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/elefante12.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/elefante13.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/elefante14.jpg',
        price: 25,
        linkUrl: '/30',
        linkUrlC: '/dosMil22/30',
        estado: 'vendido'
      },
      {
        id: 32,
        name: 'Elefante 2',
        fotoPrimera: 'http://morrisart.com.mx/images/elefante21.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/elefante22.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/elefante23.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/elefante24.jpg',
        price: 25,
        linkUrl: '/31',
        linkUrlC: '/dosMil22/31',
        estado: 'vendido'
      },
      {
        id: 33,
        name: 'Elefante 3',
        fotoPrimera: 'http://morrisart.com.mx/images/elefante31.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/elefante32.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/elefante33.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/elefante34.jpg',
        price: 25,
        linkUrl: '/32',
        linkUrlC: '/dosMil22/32',
        estado: 'vendido'
      },
      {
        id: 34,
        name: 'Esperancita',
        fotoPrimera: 'http://morrisart.com.mx/images/esperancita1.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/esperancita2.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/esperancita3.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/esperancita4.jpg',
        price: 25,
        linkUrl: '/33',
        linkUrlC: '/dosMil22/33',
        estado: ''
      },
      {
        id: 35,
        name: 'Lolita',
        fotoPrimera: 'http://morrisart.com.mx/images/lolita1.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/lolita2.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/lolita3.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/lolita4.jpg',
        price: 25,
        linkUrl: '/34',
        linkUrlC: '/dosMil22/34',
        estado: 'vendido'
      },
      {
        id: 36,
        name: 'La Guayaba',
        fotoPrimera: 'http://morrisart.com.mx/images/laGuayaba1.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/laGuayaba2.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/laGuayaba3.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/laGuayaba4.jpg',
        price: 25,
        linkUrl: '/35',
        linkUrlC: '/dosMil22/35',
        estado: ''
      },
      {
        id: 37,
        name: 'CR-24 El Destructor',
        fotoPrimera: 'http://morrisart.com.mx/images/cr241.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/cr242.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/cr243.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/cr244.jpg',
        price: 25,
        linkUrl: '/36',
        linkUrlC: '/dosMil22/36',
        estado: ''
      },
      {
        id: 38,
        name: 'Régulo',
        fotoPrimera: 'http://morrisart.com.mx/images/regulo1.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/regulo2.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/regulo3.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/regulo4.jpg',
        price: 25,
        linkUrl: '/37',
        linkUrlC: '/dosMil22/37',
        estado: 'vendido'
      },
      {
        id: 39,
        name: 'Madaleno',
        fotoPrimera: 'http://morrisart.com.mx/images/madaleno1.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/madaleno2.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/madaleno3.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/madaleno4.jpg',
        price: 25,
        linkUrl: '/38',
        linkUrlC: '/dosMil22/38',
        estado: 'vendido'
      }
    ]
  },
  luka: {
    id: 1,
    title: 'luka',
    routeName: 'luka',
    items: [
      {
        id: 1,
        name: 'Isidoro',
        fotoPrimera: 'http://morrisart.com.mx/images/isidoro1.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/isidoro2.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/isidoro3.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/isidoro4.jpg',
        price: 25,
        linkUrl: '/0',
        linkUrlC: '/luka/0',
        estado: 'vendido'
      },
      {
        id: 2,
        name: 'arnaldo',
        fotoPrimera: 'http://morrisart.com.mx/images/arnaldo1.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/arnaldo2.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/arnaldo3.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/arnaldo4.jpg',
        price: 95,
        linkUrl: '/1',
        linkUrlC: '/luka/1',
        estado: ''
      },
      {
        id: 3,
        name: 'Ruperto',
        fotoPrimera: 'http://morrisart.com.mx/images/ruperto1.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/ruperto2.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/ruperto3.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/ruperto4.jpg',
        price: 180,
        linkUrl: '/2',
        linkUrlC: '/luka/2',
        estado: ''
      },
      {
        id: 4,
        name: 'Osvaldo',
        fotoPrimera: 'http://morrisart.com.mx/images/osvaldo1.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/osvaldo2.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/osvaldo3.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/osvaldo4.jpg',
        linkUrl: '/3',
        linkUrlC: '/luka/3',
        estado: 'vendido'
      },
      {
        id: 5,
        name: 'Yeison',
        fotoPrimera: 'http://morrisart.com.mx/images/yeison1.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/yeison2.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/yeison3.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/yeison4.jpg',
        price: 253,
        linkUrl: '/4',
        linkUrlC: '/luka/4',
        estado: ''
      },
      {
        id: 6,
        name: 'Reginaldo',
        fotoPrimera: 'http://morrisart.com.mx/images/reginaldo1.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/reginaldo2.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/reginaldo3.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/reginaldo4.jpg',
        price: 75,
        linkUrl: '/5',
        linkUrlC: '/luka/5',
        estado: ''
      },
      {
        id: 7,
        name: 'El Prieto',
        fotoPrimera: 'http://morrisart.com.mx/images/elPrieto1.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/elPrieto2.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/elPrieto3.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/elPrieto4.jpg',
        price: 125,
        linkUrl: '/6',
        linkUrlC: '/luka/6',
        estado: ''
      },
      {
        id: 8,
        name: 'Emiliano',
        fotoPrimera: 'http://morrisart.com.mx/images/emiliano1.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/emiliano2.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/emiliano3.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/emiliano4.jpg',
        price: 95,
        linkUrl: '/7',
        linkUrlC: '/luka/7',
        estado: ''
      },
      {
        id: 9,
        name: 'Salustia',
        fotoPrimera: 'http://morrisart.com.mx/images/salustia1.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/salustia2.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/salustia3.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/salustia4.jpg',
        price: 16,
        linkUrl: '/8',
        linkUrlC: '/luka/8',
        estado: 'vendido'
      },
      {
        id: 10,
        name: 'Salma',
        fotoPrimera: 'http://morrisart.com.mx/images/salma1.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/salma2.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/salma3.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/salma4.jpg',
        price: 16,
        linkUrl: '/9',
        linkUrlC: '/luka/9',
        estado: 'vendido'
      },
      {
        id: 11,
        name: 'Carlota',
        fotoPrimera: 'http://morrisart.com.mx/images/carlota1.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/carlota2.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/carlota3.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/carlota4.jpg',
        price: 16,
        linkUrl: '/10',
        linkUrlC: '/luka/10',
        estado: 'vendido'
      },
      {
        id: 21,
        name: 'Walter',
        fotoPrimera: 'http://morrisart.com.mx/images/Walter1.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/WALTER2.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/WALTER3.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/WALTER4.jpg',
        price: 16,
        linkUrl: '/20',
        linkUrlC: '/luka/20',
        estado: 'vendido'
      },
      {
        id: 22,
        name: 'Huang',
        fotoPrimera: 'http://morrisart.com.mx/images/Huang1.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/HUANG2.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/HUANG3.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/HUANG4.jpg',
        price: 16,
        linkUrl: '/21',
        linkUrlC: '/luka/21',
        estado: 'vendido'
      }
    ]
  },
  sili: {
    id: 2,
    title: 'Sili',
    routeName: 'sili',
    items: [
      {
        id: 12,
        name: 'Luciferino',
        fotoPrimera: 'http://morrisart.com.mx/images/luciferino1.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/luciferino2.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/luciferino3.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/luciferino4.jpg',
        price: 115,
        linkUrl: '/11',
        linkUrlC: '/sili/11',
        estado: ''
      },
      {
        id: 13,
        name: 'Lucho',
        fotoPrimera: 'http://morrisart.com.mx/images/lucho1.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/lucho2.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/lucho3.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/lucho4.jpg',
        price: 95,
        linkUrl: '/12',
        linkUrlC: '/sili/12',
        estado: ''
      },
      {
        id: 14,
        name: 'Atenea',
        fotoPrimera: 'http://morrisart.com.mx/images/atenea1.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/atenea2.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/atenea3.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/atenea4.jpg',
        price: 115,
        linkUrl: '/13',
        linkUrlC: '/sili/13',
        estado: ''
      },
      {
        id: 15,
        name: 'Catarino',
        fotoPrimera: 'http://morrisart.com.mx/images/catarino1.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/catarino2.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/catarino3.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/catarino4.jpg',
        price: 160,
        linkUrl: '/14',
        linkUrlC: '/sili/14',
        estado: ''
      },
      {
        id: 23,
        name: 'Primitivo',
        fotoPrimera: 'http://morrisart.com.mx/images/Primitivo1.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/PRIMITIVO2.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/PRIMITIVO3.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/PRIMITIVO4.jpg',
        price: 160,
        linkUrl: '/22',
        linkUrlC: '/sili/22',
        estado: 'vendido'
      }
    ]
  },
  lukamexika: {
    id: 3,
    title: 'luka Mexika',
    routeName: 'lukamexika',
    items: [
      {
        id: 16,
        name: 'Feliciana',
        fotoPrimera: 'http://morrisart.com.mx/images/feliciana1.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/feliciana2.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/feliciana3.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/feliciana4.jpg',
        price: 38,
        linkUrl: '/15',
        linkUrlC: '/lukamexika/15',
        estado: ''
      },
      {
        id: 17,
        name: 'Xantipa',
        fotoPrimera: 'http://morrisart.com.mx/images/xantipa1.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/xantipa2.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/xantipa3.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/xantipa4.jpg',
        price: 115,
        linkUrl: '/16',
        linkUrlC: '/lukamexika/16',
        estado: ''
      },
      {
        id: 18,
        name: 'Moctezuma',
        fotoPrimera: 'http://morrisart.com.mx/images/moctezuma1.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/moctezuma2.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/moctezuma3.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/moctezuma4.jpg',
        price: 90,
        linkUrl: '/17',
        linkUrlC: '/lukamexika/17',
        estado: 'vendido'
      },
      {
        id: 19,
        name: 'Camila',
        fotoPrimera: 'http://morrisart.com.mx/images/camila1.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/camila2.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/camila3.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/camila4.jpg',
        price: 38,
        linkUrl: '/18',
        linkUrlC: '/lukamexika/18',
        estado: ''
      }
    ]
  },
  silimexika: {
    id: 4,
    title: 'Sili Mexika',
    routeName: 'silimexika',
    items: [
      {
        id: 20,
        name: 'Rene',
        fotoPrimera: 'http://morrisart.com.mx/images/rene1.jpg',
        fotoSegunda: 'http://morrisart.com.mx/images/rene2.jpg',
        fotoTercera: 'http://morrisart.com.mx/images/rene3.jpg',
        fotoCuarta: 'http://morrisart.com.mx/images/rene4.jpg',
        price: 25,
        linkUrl: '/19',
        linkUrlC: '/silimexika/19',
        estado: 'vendido'
      }
    ]
  }
}

export default SHOP_DATA
