import React from "react"
import "./menu.styles.css"
import Menusocialitem from "./menusocialitem.component"
import Whatsapp from "../../WhatsAppIcon.svg"
import Instagram from "../../insta.svg"
import Facebook from "../../fbLogo.svg"

const Menusocial = () => (
  <div className="contenedorMenuSocial">
    <Menusocialitem
      key={11}
      alt="FACEBOOK"
      imgUrl={Facebook}
      link="https://www.facebook.com/110837237450457/posts/110889364111911/?extid=0&d=n"
      //  link='https://www.facebook.com/RodolfoKelly26/'
    />

    <Menusocialitem
      key={12}
      alt="INSTAGRAM"
      imgUrl={Instagram}
      link="https://www.instagram.com/morris_art_mx/"
    />
    <Menusocialitem
      key={13}
      alt="WHATSAPP"
      imgUrl={Whatsapp}
      link="https://api.whatsapp.com/send?phone=5215554346399&text=&#191;Qu&eacute;%20informaci&oacute;n%20requieres&#63;%20escr&iacute;beme"
    />
  </div>
)

export default Menusocial
