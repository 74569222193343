import React from "react"
import "./acerca.styles.css"

// import Nuevas from '../../components/nuevas/nuevas.component';

import Contacto from "../../components/contacto/contacto.component"

import FlechaAbajo from "../../flechaAbajo.svg"

const Acerca = () => (
  <div>
    <div className="indicator"></div>
    <div className="noiseDiv"></div>

    <div className="acercaPage">
      <div className="titulo">
        ACERCA DE MI
        {/* <img className='flechaAbajo' src={FlechaAbajo} alt="bajar"/> */}
      </div>

      <div className="bio">
        <img
          className="bioImg"
          src="http://morrisart.com.mx/images/morris-en-taller-0002.jpg"
          alt="Morris en taller"
        />

        <div className="bioColumn">
          <div className="titulo">
            BIO <img className="flechaAbajo" src={FlechaAbajo} alt="bajar" />
          </div>
          <p className="textBio">
            Rodolfo Kelly Ramírez naci&oacute; en la Ciudad de M&eacute;xico
            (1956). Estudi&oacute; la licenciatura de Diseño Industrial en la
            Universidad Nacional Aut&oacute;noma de M&eacute;xico (UNAM).
            Durante sus estudios e inicios de su vida profesional diseñ&oacute;
            mobiliario y trabaj&oacute; en diferentes empresas l&iacute;deres de
            la industria mueblera donde adquiri&oacute; conocimientos sobre
            procesos industriales de madera, metales, pl&aacute;sticos y
            cer&aacute;mica.
            <br />
            <br /> Sus soluciones del d&iacute;a a d&iacute;a como diseñador
            industrial y su gusto por el desarrollo de actividades manuales lo
            llevaron en octubre de 2018 a elaborar la primera “cuchara
            calavera”. En 2020 presenta este importante proyecto que hoy lo
            identifica con el nombre de <strong>“Morris”</strong>.
          </p>
        </div>
      </div>
      <div className="eachLine">
        <img
          src="http://morrisart.com.mx/images/media/products/separadorColor-001.png"
          alt="_"
        />
      </div>

      <div className="declaracionContainer">
        <img
          className="declaraImg"
          src="http://morrisart.com.mx/images/DSC_1555.jpg"
          alt="Como agitador"
        />

        <div className="declaracion">
          <h2 className="titulo">
            DECLARACI&Oacute;N ART&Iacute;STICA{" "}
            <img className="flechaAbajo" src={FlechaAbajo} alt="bajar" />
          </h2>
          <p className="textDeclaracion">
            Cada cuchara rescatada ha sido testigo de amenas conversaciones en
            una cantina, de solitarias comidas corridas en una fonda o han
            deleitado el paladar de innumerables comensales en un famoso
            restaurante. <br />
            <br /> Mi propuesta consiste en dar una nueva oportunidad de vida a
            los cubiertos, en esta serie, principalmente de cucharas que han
            sido desechadas por viejas, por moda o simplemente porque ya no son
            deseadas.
            <br />
            <br />
          </p>
        </div>
      </div>

      <div className="declaracionContainer">
        <p className="textDosDeclaracion">
          Las cucharas son calaveras porque mi primera relaci&oacute;n con la
          muerte fue a la temprana edad de 11 meses con una tragedia que marca
          mi vida. As&iacute;, conforme pasan los años descubro que mi hermano
          mayor, uno de mis grandes c&oacute;mplices, cumple años el d&iacute;a
          primero de noviembre “D&iacute;a de Muertos”, una celebraci&oacute;n
          popular mexicana para honrar la memoria y la presencia de los muertos
          familiares. Es aqu&iacute; donde encuentro la oportunidad de compartir
          mi duelo. <br />
          <br /> En mi labor creativa, cada cuchara est&aacute; identificada con
          un nombre espec&iacute;fico y en algunos casos, se utilizan alias
          mexicanos. <br />
          <br /> Si adquieres una de las cucharas que aqu&iacute; presento, ella
          tendr&aacute; una nueva oportunidad de vida al convertirse en
          compañera de tu historia.
        </p>
      </div>
    </div>
    <Contacto />
  </div>
)

export default Acerca
