import React from "react"
import "./homepage.styles.css"
import Directory from "../../components/directory/directory.component"

import Herobanner from "../../components/herobanner/herobanner.component"
import Palabrasinter from "../../components/palabrasinter/palabrasinter.component"
import Uso from "../../components/uso/uso.component"
import PalabrasMorris from "../../components/palabrasmorris/palabrasmorris.component"
import Nuevas from "../../components/nuevas/nuevas.component"
// import FullMenu from '../../components/menu/fullmenu.component';
// import Footer from '../../components/footer/footer.component';
import Contacto from "../../components/contacto/contacto.component"

import FlechaAbajo from "../../flechaAbajo.svg"

const HomePage = () => (
  <div className="homepage">
    {/* <FullMenu /> */}
    <div className="indicator"></div>
    <div className="noiseDiv"></div>
    <Herobanner />
    <div className="titulo">
      COLECCIONES <img className="flechaAbajo" src={FlechaAbajo} alt="bajar" />
    </div>
    <Directory />
    <Palabrasinter />
    <Uso />
    <PalabrasMorris />
    <div className="tituloNuevas">
      NUEVAS CREACIONES{" "}
      <img className="flechaAbajo" src={FlechaAbajo} alt="bajar" />
    </div>
    <Nuevas />
    <Contacto />
    {/* <Footer /> */}
  </div>
)

export default HomePage
