import React from "react"
import { Switch, Route } from "react-router-dom"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import NotFoundPage from "./pages/notfound/notfound.component"
import HomePage from "./pages/homepage/homepage.component"
import ColectionPage from "./pages/collectionpage/collectionpage.component"
import FullMenu from "./components/menu/fullmenu.component"
import Footer from "./components/footer/footer.component"
import Contacto from "./components/contacto/contacto.component"
import ScrollToTop from "./components/scrolltop/scrollTop.component"
import Acerca from "./pages/acerca/acerca.component"
import Aviso from "./pages/aviso/avisop.component"
// import { animateScroll as scroll} from 'react-scroll';
// import logo from './logo.svg';
import "./App.css"

import { setCurrentUser } from "./redux/user/user.actions"
import { selectCurrentUser } from "./redux/user/user.selectors"

function App() {
  return (
    <div className="App">
      <FullMenu />
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/colecciones" component={ColectionPage} />
        <Route exact path="/contacto" component={Contacto} />
        <Route exact path="/acerca-de-mi" component={Acerca} />
        <Route exact path="/aviso" component={Aviso} />
        {/* <Route component={NotFoundPage}/>*/}
      </Switch>
      <Footer />
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
})

const mapDispatchToProps = dispatch => ({
  setCurrentUser: user => dispatch(setCurrentUser(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
