import React from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { auth } from "../../firebase/firebase.utils"

import "./menu.styles.css"
import logo from "../../logo-MORRIS.svg"
import Menu from "./menu.component"
import MenuMobile from "./menuMobile.component"
import Menusocial from "./menusocial.component"
import { selectCartHidden } from "../../redux/cart/cart.selectors"
import { selectCurrentUser } from "../../redux/user/user.selectors"
import CartIcon from "../cart-icon/cart-icon.component"
import CartDropdown from "../cart-dropdown/cart-dropdown.component"

const fullMenu = ({ currentUser, hidden }) => (
  <div className="menu unselectable">
    <Link className="logoMorris" to="/">
      <div className="logoMorris">
        <img src={logo} alt="DobleErre" />
      </div>
    </Link>

    <MenuMobile />

    <div
      data-aos="fade-right"
      //  data-aos="zoom-in-up"
      data-aos-offset="200"
      data-aos-delay="10"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false"
      data-aos-anchor-placement="center-bottom"
      className="contenedorMenuAnimate"
    >
      <Menu />
    </div>

    <div
      data-aos="fade-left"
      //  data-aos="zoom-in-up"
      data-aos-offset="200"
      data-aos-delay="10"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false"
      data-aos-anchor-placement="center-bottom"
      className="contenedorMenuSocialAnimate"
    >
      <Menusocial />

      {currentUser ? (
        <div className="option" onClick={() => auth.signOut()}>
          SIGN OUT
        </div>
      ) : (
        <Link className="option" to="/signin">
          SIGN IN
        </Link>
      )}

      <CartIcon />
      {hidden ? null : <CartDropdown />}
    </div>
  </div>
)

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  hidden: selectCartHidden
})

// const mapStateToProps = (state) => ({
//     currentUser: state.user.currentUser
// });

export default connect(mapStateToProps)(fullMenu)
