import React from "react"
import { connect } from "react-redux"

import { createStructuredSelector } from "reselect"

import { selectDirectorySections } from "../../redux/directory/directory.selectors"

import Coleccion from "../../components/coleccion/coleccion.component"
import "./directory.styles.css"

import AOS from "aos"
import "aos/dist/aos.css" // You can also use <link> for styles

const Directory = ({ sections }) => (
  <div className="directory-menu unselectable">
    {sections.map(({ id, ...otherSectionProps }) => (
      <Coleccion key={id} {...otherSectionProps} />
    ))}
  </div>
)
const mapStateToProps = createStructuredSelector({
  sections: selectDirectorySections
})

AOS.init()

export default connect(mapStateToProps)(Directory)
