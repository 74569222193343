import React from "react"
import "./contactowhats.styles.css"
import loWhats from "../../whatsApp.svg"

const Whats = ({ title, imageUrl }) => (
  <div className="contenedorWhatsapp">
    <div className="whatsapp">
      <h1 className="txtContanto unselectable">
        VENTAS <br />
        POR WHATSAPP
        <br />
        DIRECTO CON
        <br />
        EL ARTISTA
      </h1>
      <a
        className="whatsBtn"
        target="_blank"
        href="https://api.whatsapp.com/send?phone=5215554346399&text=&#191;Qu&eacute;%20informaci&oacute;n%20requieres&#63;%20escr&iacute;beme"
      >
        <img src={loWhats} alt="whatsapp" />
      </a>
    </div>
  </div>
)

export default Whats
