// reflected
const ITEM_DATA = [
  {
    id: 1,
    title: 'Luka',
    name: 'Isidoro',
    fotoPrimera: 'http://morrisart.com.mx/images/isidoro1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/isidoro2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/isidoro3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/isidoro4.jpg',
    fecha: 2020,
    largo: 19.0,
    peso: 57.0,
    price: 4100.0,
    dolares: 253.0,
    estado: 'vendido',
    caja: 'cajaLuka'
  },
  {
    id: 2,
    title: 'Luka',
    name: 'Arnaldo',
    fotoPrimera: 'http://morrisart.com.mx/images/arnaldo1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/arnaldo2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/arnaldo3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/arnaldo4.jpg',
    fecha: 2020,
    largo: 18.6,
    peso: 38.0,
    price: 1900.0,
    dolares: 95.0,
    estado: '',
    caja: 'cajaLuka'
  },
  {
    id: 3,
    title: 'Luka',
    name: 'Ruperto',
    fotoPrimera: 'http://morrisart.com.mx/images/ruperto1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/ruperto2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/ruperto3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/ruperto4.jpg',
    fecha: 2020,
    largo: 21.1,
    peso: 74.0,
    price: 3600.0,
    dolares: 180.0,
    addClass: 'noPrice',
    estado: '',
    caja: 'cajaLuka'
  },
  {
    id: 4,
    title: 'Luka',
    name: 'Osvaldo',
    fotoPrimera: 'http://morrisart.com.mx/images/osvaldo1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/osvaldo2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/osvaldo3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/osvaldo4.jpg',
    fecha: 2020,
    largo: 18.8,
    peso: 52.0,
    price: 3800.0,
    dolares: 235.0,
    estado: 'vendido',
    caja: 'cajaLuka'
  },
  {
    id: 5,
    title: 'Luka',
    name: 'Yeison',
    fotoPrimera: 'http://morrisart.com.mx/images/yeison1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/yeison2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/yeison3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/yeison4.jpg',
    fecha: 2020,
    largo: 20.8,
    peso: 74.0,
    price: 2900.0,
    dolares: 145.0,
    estado: '',
    caja: 'cajaLuka'
  },
  {
    id: 6,
    title: 'Luka',
    name: 'Reginaldo',
    fotoPrimera: 'http://morrisart.com.mx/images/reginaldo1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/reginaldo2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/reginaldo3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/reginaldo4.jpg',
    fecha: 2020,
    largo: 20.0,
    peso: 34.0,
    price: 1500.0,
    dolares: 75.0,
    estado: '',
    caja: 'cajaLuka'
  },
  {
    id: 7,
    title: 'Luka',
    name: 'El Prieto',
    fotoPrimera: 'http://morrisart.com.mx/images/elPrieto1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/elPrieto2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/elPrieto3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/elPrieto4.jpg',
    fecha: 2020,
    largo: 20.3,
    peso: 39.0,
    price: 2500.0,
    dolares: 125.0,
    addClass: 'noPrice',
    estado: '',
    caja: 'cajaLuka'
  },
  {
    id: 8,
    title: 'Luka',
    name: 'Emiliano',
    fotoPrimera: 'http://morrisart.com.mx/images/emiliano1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/emiliano2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/emiliano3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/emiliano4.jpg',
    fecha: 2020,
    largo: 18.5,
    peso: 23.0,
    price: 1900.0,
    dolares: 95.0,
    estado: '',
    caja: 'cajaLuka'
  },
  {
    id: 9,
    title: 'Luka',
    name: 'Salustia',
    fotoPrimera: 'http://morrisart.com.mx/images/salustia1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/salustia2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/salustia3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/salustia4.jpg',
    fecha: 2020,
    largo: 17.5,
    peso: 31.0,
    price: 2300.0,
    dolares: 140.0,
    estado: 'vendido',
    caja: 'cajaLuka'
  },
  {
    id: 10,
    title: 'Luka',
    name: 'Salma',
    fotoPrimera: 'http://morrisart.com.mx/images/salma1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/salma2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/salma3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/salma4.jpg',
    fecha: 2020,
    largo: 16.6,
    peso: 30.0,
    price: 2300.0,
    dolares: 140.0,
    estado: 'vendido',
    caja: 'cajaLuka'
  },
  {
    id: 11,
    title: 'Luka',
    name: 'Carlota',
    fotoPrimera: 'http://morrisart.com.mx/images/carlota1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/carlota2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/carlota3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/carlota4.jpg',
    fecha: 2020,
    largo: 21.1,
    peso: 72.0,
    price: 4500.0,
    dolares: 278.0,
    estado: 'vendido',
    caja: 'cajaLuka'
  },

  {
    id: 12,
    title: 'Sili',
    name: 'Luciferino',
    fotoPrimera: 'http://morrisart.com.mx/images/luciferino1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/luciferino2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/luciferino3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/luciferino4.jpg',
    fecha: 2020,
    largo: 12.2,
    peso: 50.0,
    price: 2300.0,
    dolares: 115.0,
    addClass: 'noPrice',
    estado: '',
    caja: 'cajaSili'
  },
  {
    id: 13,
    title: 'Sili',
    name: 'Lucho',
    fotoPrimera: 'http://morrisart.com.mx/images/lucho1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/lucho2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/lucho3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/lucho4.jpg',
    fecha: 2020,
    largo: 11.7,
    peso: 37.0,
    price: 1900.0,
    dolares: 95.0,
    estado: '',
    caja: 'cajaSili'
  },
  {
    id: 14,
    title: 'Sili',
    name: 'Atenea',
    fotoPrimera: 'http://morrisart.com.mx/images/atenea1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/atenea2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/atenea3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/atenea4.jpg',
    fecha: 2020,
    largo: 12.7,
    peso: 58.0,
    price: 2300.0,
    dolares: 115.0,
    estado: '',
    caja: 'cajaSili'
  },
  {
    id: 15,
    title: 'Sili',
    name: 'Catarino',
    fotoPrimera: 'http://morrisart.com.mx/images/catarino1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/catarino2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/catarino3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/catarino4.jpg',
    fecha: 2020,
    largo: 14.4,
    peso: 48.0,
    price: 1900.0,
    dolares: 95.0,
    estado: '',
    caja: 'cajaSili'
  },

  {
    id: 16,
    title: 'Luka Mexika',
    name: 'Feliciana',
    fotoPrimera: 'http://morrisart.com.mx/images/feliciana1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/feliciana2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/feliciana3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/feliciana4.jpg',
    fecha: 2020,
    largo: 18.2,
    peso: 16.0,
    price: 750.0,
    dolares: 38.0,
    estado: '',
    caja: 'cajaLuka'
  },
  {
    id: 17,
    title: 'Luka Mexika',
    name: 'Xantipa',
    fotoPrimera: 'http://morrisart.com.mx/images/xantipa1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/xantipa2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/xantipa3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/xantipa4.jpg',
    fecha: 2020,
    largo: 19.9,
    peso: 43.0,
    price: 2300.0,
    dolares: 115.0,
    estado: '',
    caja: 'cajaLuka'
  },
  {
    id: 18,
    title: 'Luka Mexika',
    name: 'Moctezuma',
    fotoPrimera: 'http://morrisart.com.mx/images/moctezuma1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/moctezuma2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/moctezuma3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/moctezuma4.jpg',
    fecha: 2020,
    largo: 19.8,
    peso: 41.0,
    price: 2900.0,
    dolares: 179.0,
    estado: 'vendido',
    caja: 'cajaLuka'
  },
  {
    id: 19,
    title: 'Luka Mexika',
    name: 'Camila',
    fotoPrimera: 'http://morrisart.com.mx/images/camila1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/camila2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/camila3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/camila4.jpg',
    fecha: 2020,
    largo: 18.0,
    peso: 16.0,
    price: 750.0,
    dolares: 38.0,
    estado: '',
    caja: 'cajaLuka'
  },
  {
    id: 20,
    title: 'Sili Mexika',
    name: 'Rene',
    fotoPrimera: 'http://morrisart.com.mx/images/rene1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/rene2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/rene3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/rene4.jpg',
    fecha: 2020,
    largo: 11.3,
    peso: 18.0,
    price: 980.0,
    dolares: 60.0,
    estado: 'vendido',
    caja: 'cajaSili'
  },

  {
    id: 21,
    title: 'Luka',
    name: 'Walter',
    fotoPrimera: 'http://morrisart.com.mx/images/Walter1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/WALTER2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/WALTER3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/WALTER4.jpg',
    fecha: 2021,
    largo: 19.0,
    peso: 53.0,
    price: 4100.0,
    dolares: 253.0,
    estado: 'vendido',
    caja: 'cajaLuka'
  },

  {
    id: 22,
    title: 'Luka',
    name: 'Huang',
    fotoPrimera: 'http://morrisart.com.mx/images/Huang1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/HUANG2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/HUANG3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/HUANG4.jpg',
    fecha: 2021,
    largo: 20.5,
    peso: 50.0,
    price: 4100.0,
    dolares: 253.0,
    estado: 'vendido',
    caja: 'cajaLuka'
  },

  {
    id: 23,
    title: 'Sili',
    name: 'Primitivo',
    fotoPrimera: 'http://morrisart.com.mx/images/Primitivo1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/PRIMITIVO2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/PRIMITIVO3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/PRIMITIVO4.jpg',
    fecha: 2021,
    largo: 13.5,
    peso: 16.0,
    price: 2900.0,
    dolares: 179.0,
    estado: 'vendido',
    caja: 'cajaLuka'
  },

  {
    id: 24,
    title: '2022',
    name: 'Albino',
    fotoPrimera: 'http://morrisart.com.mx/images/albino1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/albino2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/albino3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/albino4.jpg',
    fecha: 2022,
    largo: 26.8,
    peso: 100.0,
    price: 1450.0,
    dolares: 72.0,
    estado: '',
    caja: 'cajaLuka'
  },

  {
    id: 25,
    title: '2022',
    name: 'Lino',
    fotoPrimera: 'http://morrisart.com.mx/images/lino1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/lino2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/lino3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/lino4.jpg',
    fecha: 2022,
    largo: 22.2,
    peso: 92.0,
    price: 1750.0,
    dolares: 87.0,
    estado: 'vendido',
    caja: 'cajaLuka'
  },
  {
    id: 26,
    title: '2022',
    name: 'Nacho',
    fotoPrimera: 'http://morrisart.com.mx/images/nacho1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/nacho2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/nacho3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/nacho4.jpg',
    fecha: 2022,
    largo: 5.6,
    peso: 13.0,
    price: 650.0,
    dolares: 32.0,
    estado: 'vendido',
    caja: 'cajaLuka'
  },

  {
    id: 27,
    title: '2022',
    name: 'Pedro',
    fotoPrimera: 'http://morrisart.com.mx/images/pedro1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/pedro2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/pedro3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/pedro4.jpg',
    fecha: 2022,
    largo: 5.5,
    peso: 13.0,
    price: 650.0,
    dolares: 32.0,
    estado: 'vendido',
    caja: 'cajaLuka'
  },

  {
    id: 28,
    title: '2022',
    name: 'El Púas',
    fotoPrimera: 'http://morrisart.com.mx/images/elPuas1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/elPuas2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/elPuas3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/elPuas4.jpg',
    fecha: 2022,
    largo: 21.1,
    peso: 80.0,
    price: 3600.0,
    dolares: 180.0,
    estado: '',
    caja: 'cajaLuka'
  },

  {
    id: 29,
    title: '2022',
    name: 'Roberto',
    fotoPrimera: 'http://morrisart.com.mx/images/roberto1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/roberto2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/roberto3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/roberto4.jpg',
    fecha: 2022,
    largo: 5.5,
    peso: 13.0,
    price: 650.0,
    dolares: 32.0,
    estado: 'vendido',
    caja: 'cajaLuka'
  },

  {
    id: 30,
    title: '2022',
    name: 'Bartolo',
    fotoPrimera: 'http://morrisart.com.mx/images/bartolo1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/bartolo2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/bartolo3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/bartolo4.jpg',
    fecha: 2022,
    largo: 24.8,
    peso: 66.0,
    price: 1250.0,
    dolares: 62.0,
    estado: '',
    caja: 'cajaLuka',
    consignacion: true,
    addClass: 'noPrice'
  },

  {
    id: 31,
    title: '2022',
    name: 'Elefante 1',
    fotoPrimera: 'http://morrisart.com.mx/images/elefante11.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/elefante12.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/elefante13.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/elefante14.jpg',
    fecha: 2022,
    largo: 3.8,
    peso: 11.0,
    price: 270.0,
    dolares: 13.0,
    estado: 'vendido',
    caja: 'cajaLuka'
  },
  {
    id: 32,
    title: '2022',
    name: 'Elefante 2',
    fotoPrimera: 'http://morrisart.com.mx/images/elefante21.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/elefante22.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/elefante23.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/elefante24.jpg',
    fecha: 2022,
    largo: 4.1,
    peso: 12.0,
    price: 270.0,
    dolares: 13.0,
    estado: 'vendido',
    caja: 'cajaLuka'
  },

  {
    id: 33,
    title: '2022',
    name: 'Elefante 3',
    fotoPrimera: 'http://morrisart.com.mx/images/elefante31.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/elefante32.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/elefante33.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/elefante34.jpg',
    fecha: 2022,
    largo: 3.9,
    peso: 8.0,
    price: 270.0,
    dolares: 13.0,
    estado: 'vendido',
    caja: 'cajaLuka'
  },

  {
    id: 34,
    title: '2022',
    name: 'Esperancita',
    fotoPrimera: 'http://morrisart.com.mx/images/esperancita1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/esperancita2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/esperancita3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/esperancita4.jpg',
    fecha: 2022,
    largo: 18.3,
    peso: 49.0,
    price: 980.0,
    dolares: 49.0,
    estado: '',
    caja: 'cajaLuka'
  },

  {
    id: 35,
    title: '2022',
    name: 'Lolita',
    fotoPrimera: 'http://morrisart.com.mx/images/lolita1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/lolita2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/lolita3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/lolita4.jpg',
    fecha: 2022,
    largo: 22.8,
    peso: 86.0,
    price: 1750.0,
    dolares: 87.0,
    estado: 'vendido',
    caja: 'cajaLuka'
  },

  {
    id: 36,
    title: '2022',
    name: 'La Guayaba',
    fotoPrimera: 'http://morrisart.com.mx/images/laGuayaba1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/laGuayaba2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/laGuayaba3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/laGuayaba4.jpg',
    fecha: 2022,
    largo: 22.6,
    peso: 79.0,
    price: 1750.0,
    dolares: 87.0,
    estado: 'vendido',
    caja: 'cajaLuka',
    consignacion: true,
    addClass: 'noPrice'
  },

  {
    id: 37,
    title: '2022',
    name: 'CR-24 El Destructor',
    fotoPrimera: 'http://morrisart.com.mx/images/cr241.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/cr242.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/cr243.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/cr244.jpg',
    fecha: 2022,
    largo: 23.2,
    peso: 163.0,
    price: 1250.0,
    dolares: 62.0,
    estado: '',
    caja: 'cajaLuka'
  },

  {
    id: 38,
    title: '2022',
    name: 'Régulo',
    fotoPrimera: 'http://morrisart.com.mx/images/regulo1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/regulo2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/regulo3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/regulo4.jpg',
    fecha: 2022,
    largo: 5.6,
    peso: 12.0,
    price: 650.0,
    dolares: 32.0,
    estado: 'vendido',
    caja: 'cajaLuka'
  },

  {
    id: 39,
    title: '2022',
    name: 'Madaleno',
    fotoPrimera: 'http://morrisart.com.mx/images/madaleno1.jpg',
    fotoSegunda: 'http://morrisart.com.mx/images/madaleno2.jpg',
    fotoTercera: 'http://morrisart.com.mx/images/madaleno3.jpg',
    fotoCuarta: 'http://morrisart.com.mx/images/madaleno4.jpg',
    fecha: 2022,
    largo: 5.5,
    peso: 12.0,
    price: 650.0,
    dolares: 32.0,
    estado: 'vendido',
    caja: 'cajaLuka'
  }
]

export default ITEM_DATA
