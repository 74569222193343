import React from "react"
import "./menu.styles.css"
import { Link, withRouter } from "react-router-dom"

const Menusocialitem = ({ alt, imgUrl, link }) => (
  <a className="socialBtn" target="_blank" href={link}>
    <img src={imgUrl} alt={alt} />
  </a>
)

export default withRouter(Menusocialitem)
