import React from "react"
import "./menu.styles.css"
import Menuitem from "./menuItem.component"
import DobleErre from "../../dobleR.svg"
import { slide as Menu } from "react-burger-menu"

class MenuMobile extends React.Component {
  constructor() {
    super()

    this.state = {
      sections: [
        {
          id: 14,
          name: "home",
          foto: "",
          informacion: "",
          link: "/"
        },
        {
          id: 11,
          name: "acerca de mi",
          foto: "",
          informacion: "sili es caracol en grecoromani azteka",
          link: "/acerca-de-mi"
        },
        {
          id: 12,
          name: "colecciones",
          foto: "",
          informacion: "",
          link: "/colecciones"
        },
        {
          id: 13,
          name: "contacto",
          foto: "",
          informacion: "",
          link: "/contacto"
        }
      ]
    }
  }

  showSettings(event) {
    event.preventDefault()
  }

  render() {
    return (
      <Menu>
        <div className="contenedorMenuMobile">
          {this.state.sections.map(({ name, id, link }) => (
            <Menuitem key={id} nombre={name.toUpperCase()} link={link} />
          ))}

          <img className="dobleErreMobile" src={DobleErre} alt="doble erre" />
        </div>
      </Menu>
    )
  }
}

export default MenuMobile
