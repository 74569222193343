import React from "react"
import SlideHB from "../slide/slide.component"
import "./herobanner.styles.css"

// import styled from 'styled-components';

import HeroSlider, {
  Slide,
  ButtonsNav,
  Nav
  // OverlayContainer,
} from "hero-slider"

//   import { H2, H3 } from 'components/UI/Text';

// const StyledOverlayContainer = styled(OverlayContainer)`
//     &&& {
//     display: flex;
//     flex-flow: column;
//     align-items: center;
//     justify-content: center;
//     width: 100%;
//     height: 100%;
//     margin: 0 auto;
//     background-color: rgba(0, 0, 0, 0.33);
//     text-align: center;
//     H2, H3 {
//         margin: 0 36px;
//     }
//     }
// `;

// http://morrisart.com.mx/images/catarino1.jpg

class Herobanner extends React.Component {
  constructor() {
    super()

    this.state = {
      sections: [
        {
          id: 1,
          coleccion: "sili mexika",
          foto: "http://morrisart.com.mx/images/media/products/HB-lukaMexika.png",
          informacion: "",
          link: ""
        },
        {
          id: 2,
          coleccion: "luka mexica",
          foto: "http://morrisart.com.mx/images/media/products/HB-luka_Pr04XV4.png",
          informacion: "",
          link: ""
        },
        {
          id: 3,
          coleccion: "luka mexica",
          foto: "http://morrisart.com.mx/images/media/products/HB-luka_Pr04XV4.png",
          informacion: "",
          link: ""
        },
        {
          id: 4,
          coleccion: "luka mexica",
          foto: "http://morrisart.com.mx/images/media/products/HB-luka_Pr04XV4.png",
          informacion: "",
          link: ""
        }
      ]
    }
  }

  render() {
    return (
      // <div className='hb-slider'>

      //     {
      //         this.state.sections.map(({title, foto, id}) => (
      //             <SlideHB key={id} title={title} imageUrl={foto} />
      //         ))
      //     }

      // </div>

      <HeroSlider
        orientation="horizontal"
        initialSlide={1}
        style={{
          color: "#FFF",
          backgroundPosition: "center bottom"
        }}
        settings={{
          slidingDuration: 500,
          slidingDelay: 100,
          shouldAutoplay: true,
          shouldDisplayButtons: true,
          autoplayDuration: 5000,
          height: "90vmin"
        }}
      >
        {/* <StyledOverlayContainer>
        <H2>
          Zoom Slider
        </H2>
        <H3>
          Slides&apos; backgroundAnimation prop set to &apos;zoom&apos; (you may have to
          reload the page if it&apos;s already over)
        </H3>
      </StyledOverlayContainer> */}

        <Slide
          // navDescription="Hallstatt - Austria"
          background={{
            backgroundImage: "http://morrisart.com.mx/images/HB-Luka.png",
            backgroundAnimation: "fade"
          }}
        />

        <Slide
          // navDescription="Hvitserkur - Iceland"
          background={{
            backgroundImage: "http://morrisart.com.mx/images/HB-uso1.png",
            backgroundAnimation: "fade"
          }}
        />
        <Slide
          // navDescription="Hvitserkur - Iceland"
          background={{
            backgroundImage: "http://morrisart.com.mx/images/HB-LukaMexika.png",
            backgroundAnimation: "fade"
          }}
        />
        <Slide
          // navDescription="Hvitserkur - Iceland"
          background={{
            backgroundImage: "http://morrisart.com.mx/images/HB-uso2.png",
            backgroundAnimation: "fade"
          }}
        />

        {/* <ButtonsNav
        isNullAfterThreshold
        position={{
          top: 0,
          left: '50%',
          transform: 'translateX(-50%)'
        }}
      /> */}
        <Nav
          style={{
            bottom: "1.5rem",
            left: "50%",
            transform: "translateX(-10%)"
          }}
        />
      </HeroSlider>
    )
  }
}

export default Herobanner
