import React from 'react'
import { withRouter } from 'react-router-dom'

import './coleccion.styles.css'

const Coleccion = ({ title, imageUrl, match, history, linkUrl }) => (
  <div className="eachColeccion">
    <div
      //  data-aos="fade"
      data-aos="zoom-out-up"
      data-aos-offset="100"
      data-aos-delay="2"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false"
      //  data-aos-anchor-placement="center-bottom"
      data-aos-anchor-placement="top-bottom"
      className="contenedorColeccion"
    >
      <div
        className="coleccion"
        onClick={() => history.push(`${match.url}${linkUrl}`)}
      >
        <div className="coleccionBtn">
          <img src={imageUrl} alt={'Colección ' + title} />
          <h1 className="txtColeccion unselectable"></h1>
          {/* <h3 className='txtYear'>2020</h3> */}
        </div>
      </div>
    </div>

    <div className="eachLine">
      <img
        src="http://morrisart.com.mx/images/media/products/separadorColor-001.png"
        alt="_"
      />
    </div>
  </div>
)

export default withRouter(Coleccion)
