import React from "react"
import CollectionItem from "../collection-item/collection-item.component"

import "./collection-preview.styles.css"
import FlechaAbajo from "../../flechaAbajo.svg"

const CollectionPreview = ({ title, items }) => (
  <div className="collection-preview">
    <p>
      COLECCI&Oacute;N
      <br />
      <span className="titleCol">
        {title.toUpperCase()}
        <img className="flechaAbajo" src={FlechaAbajo} alt="bajar" />
      </span>
    </p>
    {/* <h1 className='title'>
              {title.toUpperCase()}
        </h1> */}
    {/* <div className='preview'>
            {items.map(({id, ...otherItemProps}) => (
               <CollectionItem key={id} { ...otherItemProps } />
            ))}
        </div> */}

    <div
      data-aos="zoom-out-up"
      //  data-aos="zoom-in-up"
      data-aos-offset="200"
      data-aos-delay="10"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false"
      data-aos-anchor-placement="top-bottom"
      data-aos-debounce-delay="50"
      className="preview unselectable"
    >
      {items
        // .filter((item, idx) => idx < 4)
        .map(item => (
          <CollectionItem key={item.id} item={item} />
        ))}
    </div>
  </div>
)

export default CollectionPreview
